<template>
    <!-- 弹框 -->
    <el-dialog v-loading="loading" class="dialog" title="新增" :visible.sync="show" :before-close="onCancel">
        <el-form></el-form>
        <div class="search">
            <div>
                <el-cascader class="cascader" style="margin-right: 20px" ref="cascader" v-model="selectedOptions" :options="addressList" clearable :props="regionParams" placeholder="请选择省、市" @change="addressChoose"></el-cascader>
                <el-input v-model="supplierName" placeholder="请输入供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable style="margin-right: 20px"></el-input>
                <el-button type="primary" @click="changeall">全 选</el-button>
            </div>
            <div>
            </div>
        </div>
        <div class="globle_table">
            <el-table :data="tableData" style="width: 100%" ref="multipleTable" tooltip-effect="dark" row-key="supplierId" @select-all="selectAll" @select="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="province" label="省" min-width="120"></el-table-column>
                <el-table-column prop="city" label="市" min-width="120"></el-table-column>
                <el-table-column prop="supplierName" label="供应商名称" min-width="120"></el-table-column>

                <el-table-column prop="address" label="地址" min-width="220"></el-table-column>
            </el-table>
        </div>
        <div class="pagebox">
            <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper,sizes, total" :total="totalItemsCount"></el-pagination>
        </div>
        <span slot="footer">
            <el-button @click="onCancel">取 消</el-button>
            <el-button type="primary" :disabled="btdisabled" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show(val) {
            if (val) {
                this.Selectlist = this.productalllist
                    ? this.productalllist
                    : [];
                this.productList();
            }
        },
    },
    data() {
        return {
            loading: false,
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页显示的行数
            pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
            totalItemsCount: 0,
            tableData: [],
            supplierName: "",
            productalllist: [],
            Selectlist: [],
            btdisabled: false,
            regionParams: {
                label: "name",
                value: "code",
                expandTrigger: "hover",
            },
            // options: require('../../../assets/pca-code.json'), // 省市区数据(三维数组)
            addressList: [],
            selectedOptions: [], // 选择的地区
            province: "", // 省
            city: "", // 市
            provinceCode: "", // 省
            cityCode: "", // 市
        };
    },
    created() {
        this.getAddress();
    },
    mounted() {},
    methods: {
        getAddress() {
            this.$axios.get(this.$api.getSecondDistrictInfo).then((res) => {
                if (res.data.code == 100) {
                    this.addressList = res.data.result;
                }
            });
        },
        // 选择省市区
        addressChoose(value) {
            console.log(value);
            if (value.length == 0) {
                this.province = "";
                this.city = "";
                this.provinceCode = "";
                this.cityCode = "";
            } else {
                let names =
                    this.$refs["cascader"].getCheckedNodes()[0].pathLabels;
                this.province = names[0];
                this.city = names[1];
                this.provinceCode = value[0];
                this.cityCode = value[1];
            }
            // 注意：path、pathLabels为getCheckedNodes自带的键名
            // let codes = this.$refs['cascader'].getCheckedNodes()[0].path

            this.productList();
        },
        handleSelectionChange(arr, row) {
            const bool = this.Selectlist.some(
                (v) => v.supplierId === row.supplierId
            );
            if (bool) {
                this.Selectlist = this.Selectlist.filter(
                    (v) => v.supplierId !== row.supplierId
                );
            } else {
                this.Selectlist.push(row);
            }
        },
        selectAll(arr) {
            if (arr.length !== 0) {
                arr.forEach((item) => {
                    const bool = this.Selectlist.some(
                        (v) => v.supplierId === item.supplierId
                    );
                    if (!bool) {
                        this.Selectlist.push(item);
                    }
                });
            } else {
                this.tableData.forEach((item) => {
                    this.Selectlist = this.Selectlist.filter(
                        (v) => v.supplierId !== item.supplierId
                    );
                });
            }
        },
        echoSelected() {
            // 回显选中
            if (this.Selectlist.length > 0) {
                this.$nextTick(() => {
                    this.tableData.forEach((item) => {
                        if (
                            this.Selectlist.some(
                                (v) => v.supplierId === item.supplierId
                            )
                        ) {
                            // 存在添加
                            this.$refs.multipleTable.toggleRowSelection(item);
                        }
                    });
                });
            }
        },
        changeall() {
            var loading = this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)",
            });
            this.$axios
                .get(this.$api.supplierInfoPageList, {
                    params: {
                        page: 1,
                        pageSize: 9999,
                        supplierName: this.supplierName,
                        provinceCode: this.provinceCode,
                        cityCode: this.cityCode,
                    },
                })
                .then((res) => {
                    this.echoSelected(); //重置选中
                    setTimeout(() => {
                        var storelist = this.Selectlist;
                        var pushlist = storelist.concat(res.data.result.list);
                        let uniqueDataList = pushlist.filter(
                            (item, index, self) => {
                                const firstIndex = self.findIndex(
                                    (obj) => obj.supplierId === item.supplierId
                                );
                                return index === firstIndex;
                            }
                        );
                        this.Selectlist = uniqueDataList;
                        this.echoSelected();
                        loading.close();
                        this.$forceUpdate();
                    }, 100);
                });
        },
        productList() {
            this.loading = true;
            this.$axios
                .get(this.$api.supplierInfoPageList, {
                    params: {
                        page: this.currentPage,
                        pageSize: this.pageSize,
                        supplierName: this.supplierName,
                        provinceCode: this.provinceCode,
                        cityCode: this.cityCode,
                    },
                })
                .then((res) => {
                    this.tableData = res.data.result.list;
                    this.totalItemsCount = res.data.result.totalCount;
                    this.echoSelected();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleCurrentChange(page) {
            this.currentPage = page;
            this.productList();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.productList();
        },
        onSearch() {
            this.currentPage = 1;
            this.productList();
        },
        onCancel() {
            this.Selectlist = [];
            this.selectedOptions = [];
            this.province = "";
            this.city = "";
            this.provinceCode = "";
            this.cityCode = "";
            this.echoSelected();
            this.$emit("onCancel");
        },
        onSubmit() {
            var loading = this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)",
            });
            this.btdisabled = true;
            this.Selectlist = this.Selectlist.map((v) => {
                return {
                    ...v,
                    depositAmount: v.depositAmount ? v.depositAmount : 0,
                };
            });
            setTimeout(() => {
                this.btdisabled = false;
                this.selectedOptions = [];
                this.province = "";
                this.city = "";
                this.provinceCode = "";
                this.cityCode = "";
                loading.close();
                this.$emit("onSubmit", this.Selectlist);
            }, 1000);
        },
        handleDel() {},
    },
};
</script>

<style lang="scss" scoped>
.dialog {
    ::v-deep .el-dialog__body {
        max-height: 800px;
    }
}
.pagebox {
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
        width: 90px;
    }
}

.search {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    ::v-deep .el-input {
        width: 255px;
    }
    .cascader {
        width: 300px;
        ::v-deep .el-input,
        ::v-deep .el-input__inner {
            width: 300px;
        }
    }
}
</style>
