<template>
    <div class="user">
        <back></back>
        <div class="globle_border">
            <!-- 基本信息 -->

            <div v-show="isShow">
                <h3>标书信息</h3>
                <br />
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="标期" prop="bidPeriod">
                                <el-date-picker v-model="ruleForm.bidPeriod" type="month" :picker-options="pickerOptions1" placeholder="请选择标期" format="yyyyMM" value-format="yyyyMM"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="标号" prop="tendersLabel">
                                <el-input controls v-model="ruleForm.tendersLabel" placeholder="请输入标号"></el-input>
                            </el-form-item>
                            <el-form-item label="开标时间" prop="tendersTime">
                                <el-date-picker @change="gettimezhou" :picker-options="pickerOptions" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="ruleForm.tendersTime"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="保证金交纳截止时间" prop="deadlinePayTime">
                                <el-date-picker :picker-options="pickerOptions" type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="ruleForm.deadlinePayTime"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="邀标供应商" prop="invitationType">
                                <el-radio-group v-model="ruleForm.invitationType">
                                    <el-radio :label="1">全部供应商</el-radio>
                                    <el-radio :label="2">指定供应商</el-radio>
                                </el-radio-group>
                                <el-button v-if="ruleForm.invitationType==2" style="margin-left:20px" type="primary" size="small" @click="addsupplier">添加</el-button>
                            </el-form-item>

                            <!-- <el-form-item v-if="ruleForm.eligibleType == 1" label="应标资格名称">
                                <el-input :value="selectNowTendersEligibleobj.eligibleName" disabled placeholder="暂无应标资格"></el-input>
                            </el-form-item> -->
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="标书名称" prop="tendersName">
                                <el-input v-model="ruleForm.tendersName" placeholder="请输入标书名称"></el-input>
                            </el-form-item>
                            <el-form-item label="招标类型" prop="tendersType">
                                <el-select v-model="ruleForm.tendersType" placeholder="请选择招标类型">
                                    <el-option label="商超" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="中标有效时间" prop="dateRange2">
                                <el-date-picker type="daterange" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-model="ruleForm.dateRange2" :picker-options="pickerOptions4"></el-date-picker>
                            </el-form-item>
                            <el-form-item v-if="ruleForm.eligibleType == 2" label="应标资格金额">
                                <el-input-number v-model="ruleForm.payMoney" @change="handleInput" :precision="2" :min="0" label="请输入缴费金额"></el-input-number>
                            </el-form-item>
                            <el-form-item label="应标资格类型" prop="eligibleType">
                                <el-radio-group v-model="ruleForm.eligibleType">
                                    <el-radio :label="1">平台资格</el-radio>
                                    <el-radio :label="2">独立标资格</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-show="ruleForm.invitationType==2">
                        <el-col :span="23">
                            <el-form-item>
                                <div class="table_list globle_table">
                                    <el-table :data="suppliertableData" style="width: 100%" max-height="540">
                                        <el-table-column label="序号" :show-overflow-tooltip="true" width="50">
                                            <template slot-scope="scope">
                                                {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="province" label="省"></el-table-column>
                                        <el-table-column prop="city" label="市"></el-table-column>
                                        <el-table-column prop="supplierName" label="供应商名称" min-width="280"></el-table-column>
                                        <el-table-column prop="address" label="地址" min-width="280"></el-table-column>
                                        <el-table-column label="操作" header-align="center" align="center" fixed="right">
                                            <template slot-scope="scope">
                                                <el-button class="btn" type="danger" plain @click="supplierhandleDelete(scope.row)" size="small">删除</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <el-pagination background @size-change="supplierhandleSizeChange" @current-change="supplierhandleCurrentChange" :current-page.sync="suppliercurrentPage" :page-size="supplierpageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="suppliertotalItemsCount"></el-pagination>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="23">
                            <el-form-item label="招标公告">
                                <VueEditor :config="config" ref="vueEditor" v-model="ruleForm.tendersNotice" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div style="text-align: center">
                    <el-button type="primary" @click="submitForm('ruleForm')">下一步</el-button>
                </div>
            </div>
            <!-- 表格 -->
            <div v-show="!isShow">
                <h1>新增标书</h1>
                <div style="display: flex; justify-content: space-between; margin: 20px 0px">
                    <div style="display: flex; align-items: center">
                        <div style="font-size: 16px; margin-right: 30px">
                            <span style="color: red">*</span>
                            投标数量限制
                        </div>
                        <el-input-number v-model="tendersNumber" :precision="0" :min="0"></el-input-number>
                    </div>
                    <div>
                        <el-button type="primary" @click="moneyshow">一键输入履约保证金</el-button>
                        <el-button type="primary" @click="add">新增</el-button>
                    </div>
                </div>
                <div class="table_list globle_table">
                    <el-table :data="tableData" style="width: 100%" max-height="540">
                        <el-table-column label="序号" :show-overflow-tooltip="true" width="50">
                            <template slot-scope="scope">
                                {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="barCode" label="商品条形码"></el-table-column>
                        <el-table-column prop="productName" label="商品名称"></el-table-column>
                        <el-table-column prop="brand" label="品牌"></el-table-column>
                        <el-table-column prop="factoryName" label="厂家"></el-table-column>
                        <el-table-column prop="categoryName" label="类目"></el-table-column>
                        <el-table-column prop="specs" label="规格"></el-table-column>
                        <el-table-column prop="depositAmount" label="履约保证金（元）" width="280">
                            <template slot-scope="scope">
                                <el-input-number v-model="scope.row.depositAmount" :step="0.01" @change="handleChange(scope.row)" :precision="2" :min="0" label="请输入保证金"></el-input-number>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" header-align="center" align="center" fixed="right">
                            <template slot-scope="scope">
                                <el-button class="btn" type="danger" plain @click="handleDelete(scope.row)" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
                <div style="text-align: center; margin-top: 20px">
                    <el-button type="primary" plain @click="onBack">上一步</el-button>
                    <el-button type="primary" @click="tosubmit">确定</el-button>
                </div>
            </div>
        </div>
        <!-- 弹框 -->
        <Dialog :show="dialogVisible" ref="addref" @onCancel="cancellist" @onSubmit="handleSubmit"></Dialog>
        <supplierDialog :show="supplierdialogVisible" ref="addsupplierref" @onCancel="suppliercancellist" @onSubmit="supplierhandleSubmit"></supplierDialog>

        <el-dialog :visible.sync="themoneyshow" title="履约保证金" :before-close="moneyCancel" width="30%" custom-class="customClass">
            <div class="moneybox">
                <div class="moneytitle">类目:</div>
                <el-select v-model="typenum" clearable placeholder="根据类目一键输入">
                    <el-option v-for="item in typeoptions" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </div>
            <div class="moneybox">
                <div class="moneytitle">品牌:</div>
                <el-select v-model="pinpainum" clearable placeholder="根据品牌一键输入">
                    <el-option v-for="item in pinpaioptions" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </div>

            <div class="moneybox">
                <div class="moneytitle">履约保证金:</div>
                <el-input-number v-model="moneynum" :precision="2" :step="0.01" :min="0" label="请输入保证金"></el-input-number>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="moneyCancel">取 消</el-button>
                <el-button type="primary" @click="moneysubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import VueEditor from "vue-word-editor";
import Dialog from "@/components/bidDialog.vue";
import supplierDialog from "@/components/supplierDialog.vue";

import "quill/dist/quill.snow.css";

export default {
    components: {
        VueEditor,
        Dialog,
        supplierDialog,
    },
    data() {
        return {
            themoneyshow: false,
            moneynum: 0,
            isShow: true,
            currentPage: 1, // 当前页码
            pageSize: 10, // 每页显示的行数
            pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
            totalItemsCount: 0, // 总记录数（需要从后端获取）
            suppliercurrentPage: 1, // 当前页码
            supplierpageSize: 10, // 每页显示的行数
            suppliertotalItemsCount: 0, // 总记录数（需要从后端获取）
            dialogVisible: false,
            supplierdialogVisible: false,
            productalllist: [],
            supplieralllist: [],
            ruleForm: {
                bidPeriod: "", //标期
                tendersName: "", //标书名称
                tendersLabel: "", //标号
                tendersType: "", //招标类型
                eligibleType: 1, //应标资格类型
                invitationType: 1, //供应商状态
                supplierList: [], //邀标供应商列表
                payMoney: 0, //应标资格金额
                tendersTime: "", //开标时间
                deadlinePayTime: "",
                effectiveStartTime: "", //中标有效开始时间
                effectiveEndTime: "", //中标有效结束时间
                tendersNotice: "", // 招标公告
                dateRange2: "",
                // eligiblePrice: "", //应标金额
                // eligibleId: "", //应标id
            },

            tendersNumber: 20, //投标数量
            // 开标时间<中标时间
            pickerOptions4: {
                disabledDate: (time) => {
                    // 当开标时间还未选择时，可以禁用所有未来日期
                    if (!this.ruleForm.tendersTime) {
                        return true;
                    } else {
                        return (
                            time.getTime() + 86400000 <
                            new Date(this.ruleForm.tendersTime).getTime()
                        );
                    }
                },
            },
            pickerOptions1: {
                disabledDate: (time) => {
                    return time.getTime() + 86400000 < new Date().getTime();
                },
            },
            //开标时间大于等于今天
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() < new Date().getTime();
                },
            },
            rules: {
                bidPeriod: [
                    {
                        required: true,
                        message: "请输入标期",
                        trigger: "submit",
                    },
                ],
                tendersName: [
                    {
                        required: true,
                        message: "请输入标书名称",
                        trigger: "submit",
                    },
                ],
                tendersLabel: [
                    {
                        required: true,
                        message: "请输入标号",
                        trigger: "submit",
                    },
                ],
                tendersType: [
                    {
                        required: true,
                        message: "请选择招标类型",
                        trigger: "submit",
                    },
                ],
                eligibleType: [
                    {
                        required: true,
                        message: "请选择应标资格类型",
                        trigger: "submit",
                    },
                ],
                tendersTime: [
                    {
                        required: true,
                        message: "请选择开标日期",
                        trigger: "submit",
                    },
                ],
                deadlinePayTime: [
                    {
                        required: true,
                        message: "请选择保证金交纳截止时间",
                        trigger: "submit",
                    },
                ],
                dateRange2: [
                    {
                        required: true,
                        message: "请选择日期",
                        trigger: "submit",
                    },
                ],
            },
            config: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // toggled buttons
                        ["blockquote", "code-block"],
                        ["link", "image"],
                        [{ header: 1 }, { header: 2 }], // custom button values
                        [
                            { list: "ordered" },
                            { list: "bullet" },
                            { list: "check" },
                        ],
                        [{ script: "sub" }, { script: "super" }], // superscript/subscript
                        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                        [{ direction: "rtl" }], // text direction
                    ],
                },
                // 上传图片的配置
                uploadImage: {
                    url: this.$axios.defaults.baseURL + this.$api.uploadFile,
                    name: "multipartFile",
                    // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
                    uploadSuccess: (res, insert) => {
                        if (res.data.code !== 100) {
                            this.$message.error(
                                "请上传图片格式,如JPG,JPEG,PNG文件格式"
                            );
                        } else {
                            insert(res.data.result.fileUrl);
                        }
                    },

                    showProgress: false,
                },
            },
            tableData: [],
            suppliertableData: [],
            options: [
                {
                    value: "1",
                    label: "未开始",
                },
                {
                    value: "2",
                    label: "进行中",
                },
                {
                    value: "3",
                    label: "已结束",
                },
            ],
            typeoptions: [], //缴纳保证金分类列表
            pinpaioptions: [], //缴纳保证金品牌列表
            pinpainum: "",
            typenum: "",
            db: "",
            // selectNowTendersEligibleobj: {
            //     eligibleName: "",
            // },
        };
    },

    created() {},

    methods: {
        gettimezhou(v) {
            if (v) {
                var date = new Date(new Date(v).getTime() + 7 * 86400000);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                this.ruleForm.deadlinePayTime = year + "-" + month + "-" + day;
            }
        },
        moneyCancel() {
            this.themoneyshow = false;
            this.moneynum = 0;
        },
        moneysubmit() {
            var newarrlist = this.productalllist;
            if (newarrlist) {
                for (let i = 0; i < newarrlist.length; i++) {
                    if (this.pinpainum == "" && this.typenum == "") {
                        //都为空
                        newarrlist[i].depositAmount = this.moneynum;
                    } else if (this.pinpainum != "" && this.typenum == "") {
                        //品牌不为空 分类为空
                        if (newarrlist[i].brand == this.pinpainum) {
                            newarrlist[i].depositAmount = this.moneynum;
                        }
                    } else if (this.pinpainum == "" && this.typenum != "") {
                        //分类不为空 品牌为空
                        if (newarrlist[i].categoryName == this.typenum) {
                            newarrlist[i].depositAmount = this.moneynum;
                        }
                    } else if (this.pinpainum != "" && this.typenum != "") {
                        //都不为空
                        if (
                            newarrlist[i].categoryName == this.typenum &&
                            newarrlist[i].brand == this.pinpainum
                        ) {
                            newarrlist[i].depositAmount = this.moneynum;
                        }
                    }
                }
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.pinpainum == "" && this.typenum == "") {
                        //都为空
                        this.tableData[i].depositAmount = this.moneynum;
                    } else if (this.pinpainum != "" && this.typenum == "") {
                        if (this.tableData[i].brand == this.pinpainum) {
                            //品牌不为空 分类为空
                            this.tableData[i].depositAmount = this.moneynum;
                        }
                    } else if (this.pinpainum == "" && this.typenum != "") {
                        //分类不为空 品牌为空
                        if (this.tableData[i].categoryName == this.typenum) {
                            this.tableData[i].depositAmount = this.moneynum;
                        }
                    } else if (this.pinpainum != "" && this.typenum != "") {
                        //都不为空
                        if (
                            this.tableData[i].categoryName == this.typenum &&
                            this.tableData[i].brand == this.pinpainum
                        ) {
                            this.tableData[i].depositAmount = this.moneynum;
                        }
                    }
                }
                this.productalllist = newarrlist;
            }

            this.themoneyshow = false;
            this.moneynum = 0;
        },
        moneyshow() {
            this.themoneyshow = true;
        },
        selectNowTendersEligible() {
            this.$axios.get(this.$api.selectNowTendersEligible).then((res) => {
                if (res.data.code == 100) {
                    this.selectNowTendersEligibleobj = res.data.result;
                    this.ruleForm.eligiblePrice = res.data.result.eligiblePrice; //应标金额
                    this.ruleForm.eligibleId = res.data.result.id; //应标id
                } else {
                    this.$confirm(res.data.desc, "提示", {
                        confirmButtonText: "确定",
                        type: "warning",
                        showCancelButton: false,
                        showClose: false,
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }
            });
        },
        handleInput(value) {
            // 限制金额最多两位小数
            this.ruleForm.payMoney = parseFloat(value).toFixed(2);
        },
        //添加指定供应商弹窗
        addsupplier() {
            this.supplierdialogVisible = true;
            this.$refs.addsupplierref.productalllist = this.supplieralllist;
        },

        // 关闭弹窗
        suppliercancellist() {
            this.supplierdialogVisible = false;
        },
        supplierhandleSubmit(setproductalllist) {
            this.supplierdialogVisible = false;
            var supplieralllist = setproductalllist;
            this.supplieralllist = setproductalllist;
            this.suppliertotalItemsCount = supplieralllist.length;
            this.suppliertableData = this.chunk(
                supplieralllist,
                this.supplierpageSize
            )[this.suppliercurrentPage - 1];
        },
        // 返回
        onBack() {
            this.isShow = true;
        },
        // 弹框
        add() {
            this.dialogVisible = true;
            this.$refs.addref.productalllist = this.productalllist;
        },
        cancellist() {
            // 关闭弹窗
            this.dialogVisible = false;
        },
        // 弹框确定获取缓存
        handleSubmit(setproductalllist) {
            this.dialogVisible = false;
            var productalllist = setproductalllist;
            this.productalllist = setproductalllist;
            this.totalItemsCount = productalllist.length;
            this.tableData = this.chunk(productalllist, this.pageSize)[
                this.currentPage - 1
            ];
            var arr = productalllist;
            var categorylist = [];
            var brandlist = [];
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].categoryName) {
                    categorylist.push(arr[i].categoryName);
                }

                this.typeoptions = [...new Set(categorylist)];
                if (arr[i].brand) {
                    brandlist.push(arr[i].brand);
                }
                this.pinpaioptions = [...new Set(brandlist)];
            }
        },
        chunk(arr, size) {
            let changeIndex = 0;
            let secondArr = [];
            while (changeIndex < arr.length) {
                secondArr.push(arr.slice(changeIndex, (changeIndex += size)));
            }
            return secondArr;
        },
        // 输入金额
        handleChange(row) {
            var list = this.productalllist;
            var index = list.findIndex(
                (role) => role.productSupplierId === row.productSupplierId
            );
            list[index].depositAmount = row.depositAmount;
            this.productalllist = list;
        },
        // 删除
        handleDelete(row) {
            this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
            })
                .then(() => {
                    var list = this.productalllist;
                    var index = list.findIndex(
                        (role) =>
                            role.productSupplierId === row.productSupplierId
                    );
                    list.splice(index, 1);
                    if (
                        list.length % this.pageSize == 0 &&
                        this.currentPage != 1
                    ) {
                        this.currentPage -= 1;
                    }
                    this.totalItemsCount = list.length;
                    this.tableData = this.chunk(list, this.pageSize)[
                        this.currentPage - 1
                    ];
                    this.productalllist = list;
                })
                .catch(() => {});
        },
        // 切换每页显示的条数
        handleSizeChange(e) {
            this.currentPage = 1;
            this.pageSize = e;
            this.tableData = this.chunk(this.productalllist, this.pageSize)[
                this.currentPage - 1
            ];
        },
        // 换页
        handleCurrentChange(page) {
            this.currentPage = page;
            this.tableData = this.chunk(this.productalllist, this.pageSize)[
                this.currentPage - 1
            ];
        },
        // 供应商列表切换每页显示的条数
        supplierhandleSizeChange(e) {
            this.suppliercurrentPage = 1;
            this.supplierpageSize = e;
            this.suppliertableData = this.chunk(
                this.supplieralllist,
                this.supplierpageSize
            )[this.suppliercurrentPage - 1];
        },
        // 供应商列表换页
        supplierhandleCurrentChange(page) {
            this.suppliercurrentPage = page;
            this.suppliertableData = this.chunk(
                this.supplieralllist,
                this.supplierpageSize
            )[this.suppliercurrentPage - 1];
        },
        // 供应商删除
        supplierhandleDelete(row) {
            this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
            })
                .then(() => {
                    var list = this.supplieralllist;
                    var index = list.findIndex(
                        (role) => role.supplierId === row.supplierId
                    );
                    list.splice(index, 1);
                    if (
                        list.length % this.supplierpageSize == 0 &&
                        this.suppliercurrentPage != 1
                    ) {
                        this.suppliercurrentPage -= 1;
                    }
                    this.suppliertotalItemsCount = list.length;
                    this.suppliertableData = this.chunk(
                        list,
                        this.supplierpageSize
                    )[this.suppliercurrentPage - 1];
                    this.supplieralllist = list;
                })
                .catch(() => {});
        },
        // 会话存储，下一步
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    this.$message.error("请填写相关信息！");
                } else {
                    this.ruleForm.effectiveStartTime =
                        this.ruleForm.dateRange2[0];
                    this.ruleForm.effectiveEndTime =
                        this.ruleForm.dateRange2[1];
                    this.ruleForm.supplierList = this.supplieralllist;
                    this.isShow = false;
                    this.ruleForm.tendersNotice =
                        this.$refs.vueEditor.editor.root.innerHTML;
                }
            });
        },
        tosubmit() {
            var submitform = this.ruleForm;
            submitform.tendersNumber = this.tendersNumber;
            if (this.productalllist) {
                submitform.productDtos = this.productalllist.map((v) => {
                    return {
                        ...v,
                        productId: v.productSupplierId,
                    };
                });
            } else {
                submitform.productDtos = [];
            }

            if (submitform.productDtos.length < submitform.tendersNumber) {
                this.$message.error("商品数量需大于等于投标数量限制");
                return;
            }
            // if (submitform.eligibleType == 1) {
            //     submitform.payMoney = submitform.eligiblePrice;
            // }
            var loading = this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)",
            });
            this.$axios
                .post(this.$api.tendersInfoAdd, submitform)
                .then((res) => {
                    if (res.data.code == 100) {
                        loading.close();
                        this.$message({
                            message: res.data.desc,
                            type: "success",
                        });
                        this.$router.go(-1);
                        this.productalllist = [];
                    } else {
                        loading.close();
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },
    },
};
</script>
<style scoped></style>
<style scoped lang="scss">
.globle_border {
    min-width: 1300px;
}
::v-deep .customClass {
    .el-dialog__body {
        min-height: auto;
    }
}

.user {
    .moneybox {
        display: flex;
        margin-bottom: 10px;

        .moneytitle {
            margin-right: 20px;
            align-self: center;
            width: 80px;
        }

        .el-input-number {
            width: 255px;
        }
    }

    ::v-deep .ql-editor {
        min-height: 200px;
    }

    .el-date-editor ::v-deep .el-range-input {
        background: transparent;
    }

    .el-input {
        width: 240px;
    }

    .el-range-editor.el-input__inner {
        background: #f2f2f2;
        border-radius: 10px;
        border: none;
    }

    // .el-input-number {
    //     width: 255px;
    // }

    .table_list {
        width: 100%;
        box-sizing: border-box;
        overflow-x: auto;

        .desc {
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .red {
            color: #eb2500;
        }

        .green {
            color: #069bbc;
        }

        .gray {
            color: #68706e;
        }

        .logo {
            display: block;
            width: 58px;
            height: 58px;
            border-radius: 4px;
        }

        .busilicense {
            display: block;
            width: 80px;
            height: 58px;
            border-radius: 4px;
        }

        ::v-deep .el-table__fixed-header-wrapper thead tr th {
            color: rgba(3, 16, 14, 0.9);
            background-color: #f4f6f9;
        }
    }
}
</style>
